<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12">
        <v-card dense>
          <v-toolbar color="deep-purple lighten-2" dark flat>
            <v-toolbar-title>Einstellungen</v-toolbar-title>
          </v-toolbar>
          <v-card-text> </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DataService from "@/services/SettingService.js";

export default {
  name: "SettingsForm",
  components: {},
  props: [],
  data: () => ({
    settings: {
      nummernkreise: {
        offer: "",
        delivery: "",
        bill: "",
        customer: "",
        confirmations: ""
      },
      emailtext: {
        offer: "",
        delivery: "",
        bill: "",
        customer: "",
        confirmations: ""
      },
      pdftext: {
        offer: "",
        delivery: "",
        bill: "",
        customer: "",
        confirmations: ""
      }
    },

    requiredRule: [(value) => !!value || "Dieses Feld wird benötigt!"],
    emailRules: [
      (v) => !!v || "E-mail wird benötigt!",
      (v) =>
        /.+@.+/.test(v) ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) ||
        "E-mail must be valid"
    ],
    formValid: false
  }),

  methods: {
    save() {
      DataService.save({
        id: this.id,
        data: this.settings
      })
        .then(() => {
          //if success redirecht to list
          this.$router.push({ name: "mandanten" });
          this.$notify({
            group: "dataline",
            type: "success",
            title: "Einstellungen gespeichert",
            text: "Alle Einstellugen erfolgreich gespeichert"
          });
        })
        .catch((error) => {
          //on error alert error
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Fehler",
            text: error.message
          });
        });
    }
  },
  computed: {},
  created() {
    DataService.get()
      .then((settings) => {
        this.settings = settings;
      })
      .catch((error) => {
        this.$notify({
          group: "dataline",
          type: "error",
          title: "Fehler beim laden der Einstellungen",
          text: error.message
        });
      });
  }
};
</script>
